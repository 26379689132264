// ** Redux Imports
import toast from "react-hot-toast";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";

/*=============================================
=                INITIAL STATES              =
=============================================*/
const folderData = JSON.parse(localStorage.getItem("folderData"));
const initData = {
  root: {
    index: 'root',
    isFolder: true,
    children: ['child1', 'child2'],
    data: 'Root item',
  },
  child1: {
    index: 'child1',
    children: [],
    data: 'Child item 1',
  },
  child2: {
    index: 'child2',
    isFolder: true,
    children: ['child3'],
    data: 'Child item 2',
  },
  child3: {
    index: 'child3',
    children: [],
    data: 'Child item 3',
  },
};



const initialState = {
 folderData:folderData ? folderData :initData
};

/*=============================================
=            ASYNC THUNK FUNCTIONS            =
=============================================*/
// ** GET THEME
export const getAllThemes = createAsyncThunk("getall-themes", async () => {
  const res = await axios.get(`/api/themes`);
  return res.data;
});



function extraReducers(builder) {
  //CREATE THEME
  builder.addCase(getAllThemes.pending, (state, action) => {
    return {
      ...state,
      loading: true,
      isInitialized: true,
    };
  });
  builder.addCase(getAllThemes.fulfilled, (state, action) => {
    return {
      ...state,
      themes: action.payload,
    };
  });
  builder.addCase(getAllThemes.rejected, (state, action) => {
    toast.error("An error has occured trying to fetch Themes list");
  });


}

export const mediaSlice = createSlice({
  name: "media",
  initialState,
  reducers: {
    updateFolders: (state, action) => {
        const val = action.payload;
        return{
            ...state,
            floorData:action.payload

        }
      },
  },
  extraReducers,
});

export const {  } = mediaSlice.actions;
// Action creators are generated for each case reducer function
// export const { } = authSlice.actions;

export default mediaSlice.reducer;
