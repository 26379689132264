import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { useEffect, useState, useMemo, lazy, Suspense } from "react";
import { useSelector } from "react-redux";
import {MainContainerWrapper} from './App.styled'
import { createTheme, ThemeProvider, CssBaseline } from "@mui/material";
import { getDesignTokens } from "./themes/theme";
import toast, { Toaster } from "react-hot-toast";
import Auth from "./pages/auth/Auth";

const Navbar = lazy(() => import("./components/navbar/Navbar"));
const AnimatedRoutes = lazy(() => import("./utils/AnimatedRoutes"));
const TopBar = lazy(() => import("./components/topbar/TopBar"));
const Footer = lazy(() => import("./components/footer/Footer"));
const ProjectChip = lazy(() => import("./components/projectChip/ProjectChip"));
const ThemeBar = lazy(() => import("./components/themeBar/ThemeBar"));
const UpdateScreen = lazy(() =>
  import("./components/updateScreen/UpdateScreen")
);
const AccountMain = lazy(() =>
  import("./components/accountFloat/AccountFloat")
);







function App() {
  //fetch user auth
  const auth = useSelector((state) => state.auth);
  const { userInfo } = auth;
  const themes = useSelector((state) => state.themes);

  //admin check
  const [admin, setAdmin] = useState(false);
  const theme = useMemo(() => createTheme(themes), [themes]);



  return (
    <ThemeProvider theme={theme}>
      <CssBaseline enableColorScheme />
      <Router>
        {userInfo && (
          <div
            style={{
              width: "100vw",
              height: "100vh",
              overflow: "hidden",
            }}
          >
            <div
              style={{
                width: "100%",
                height: "100%",
                display: "flex",
                flexDirection: "row",
                overflow: "hidden",
              }}
            >
              <div>
                <Suspense fallback={<div>Loading Navbar...</div>}>
                  <Navbar
                    admin={userInfo && userInfo.isSignCast}
                    auth={userInfo}
                  />
                </Suspense>
              </div>

              <Toaster position="top-right" />
              {/* <Suspense fallback={<div>Loading ProjectChip...</div>}>
                <ProjectChip />
              </Suspense> */}

              <Suspense fallback={<div>Loading AccountMain...</div>}>
                <AccountMain />
              </Suspense>
              <MainContainerWrapper>
                <Suspense fallback={<div>Loading TopBar...</div>}>
                  <TopBar userInfo={userInfo && userInfo} />
                </Suspense>
                <Suspense fallback={<div>Loading AnimatedRoutes...</div>}>
                  <div style={{overflow:'hidden'}}>
                  <AnimatedRoutes admin={admin} auth={userInfo} />
                  </div>
                </Suspense>
                <Suspense fallback={<div>Loading TopBar...</div>}>
                  <Footer userInfo={userInfo && userInfo} />
                </Suspense>
              </MainContainerWrapper>
            </div>
 
          </div>
        )}
        {!userInfo && <Auth />}
      </Router>
    </ThemeProvider>
  );
}

export default App;
