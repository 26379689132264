// ** Redux Imports
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";


// ** Axios Imports
import axios from "axios";

const initialState = {
seedFloor: '',
};

// ** FETCH All ACCOUNTS
export const seedFloorPlan = createAsyncThunk("seedFloorplan", async (floorData) => {
    console.log(floorData)

    // const res = await axios.post("/api/floorplan/seed",{floorData});
    // return res.data
 
});


function extraReducers(builder) {
  //FETCH ALL ACCOUNTS
  builder.addCase(seedFloorPlan.pending, (state, action) => {
    return {
      ...state,
      loading: true,
    };
  });
  builder.addCase(seedFloorPlan.fulfilled, (state, action) => {

    return {
      ...state,
      seedFloor:action.payload,
    };
  });
  builder.addCase(seedFloorPlan.rejected, (state, action) => {

    return {
      ...state,
      loading: false,
    };
  });

}

export const floorSlice = createSlice({
  name: "floorplan",
  initialState,
  reducers: {
  },
  extraReducers,
});



export default floorSlice.reducer;
