import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import Avatar from "@mui/material/Avatar";
import TextField from "@mui/material/TextField";
import Link from "@mui/material/Link";
import Box from "@mui/material/Box";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import { Select, Option } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { motion } from "framer-motion";
import { toast } from "react-hot-toast";
// import {getVideoDurationInSeconds}  from 'get-video-duration'
import CircularLoader from "../../components/circularLoad/CircularLoader";
// import { createTheme, ThemeProvider } from '@mui/material/styles';
// import loginPic from '../../assets/loginDesign.svg'
import { createCarousel, createHeroCarousel } from "../../redux/slices/projectSlice";
import NumberTextField from "../textFeildNumber/TextNumberOnly";
// import { ToastContainer, toast } from 'react-toastify';
import { Button } from "@mui/material";
import { useLocation } from "react-router-dom";

import {
  deleteCarousels,
  updateCarousels,
  deleteSingleCarousel,
  deleteContent,
} from "../../redux/slices/projectSlice";

//FOR FIREBASE
import { storage } from "../../firebase";
import {
  ref,
  uploadBytesResumable,
  getDownloadURL,
  deleteObject,
} from "firebase/storage";
import { v4 } from "uuid";

import CameraAltIcon from "@mui/icons-material/CameraAlt";
import VideoCameraBackIcon from "@mui/icons-material/VideoCameraBack";

import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";

import ListItemText from "@mui/material/ListItemText";

import IconButton from "@mui/material/IconButton";

import DeleteIcon from "@mui/icons-material/Delete";

import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import { SelectChangeEvent } from "@mui/material/Select";

function Copyright(props) {
  return (
    <Typography
      variant="body2"
      color="text.secondary"
      align="center"
      {...props}
    >
      {"Copyright © "}
      <Link color="inherit" href="https://mui.com/">
        SignCastMedia BuilderDirectory
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

const CreateMainCara = ({ setOpen }) => {
  const location = useLocation();
  const { pathname } = location;
  const projectId = pathname.split("/")[2];
  const [uploading, setUploading] = useState();
  const [progress, setProgress] = useState();
  const [contentPic, setContentPic] = useState("");
  const [duration, setDuration] = useState(0);
  const fileRef = useRef();
  const [fileType, setFileType] = useState("");
  const [file, setFile] = useState("");
  const [theInputKey, setTheInputKey] = useState(0);
  const project = useSelector((state) => state.project);
  const { chosenProject } = project;

  const dispatch = useDispatch();

  //Image upload fix
  const imageUploadThumb = async () => {
    setUploading(true);
    const imageRef = ref(
      storage,
      `${chosenProject && chosenProject.projectName}/${file.name + v4()}`
    );
    const uploadTask = uploadBytesResumable(imageRef, file);

    // Register three observers:
    // 1. 'state_changed' observer, called any time the state changes
    // 2. Error observer, called on failure
    // 3. Completion observer, called on successful completion
    uploadTask.on(
      "state_changed",
      (snapshot) => {
        // Observe state change events such as progress, pause, and resume
        // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
        const progress =
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        console.log("Upload is " + progress + "% done");
        setProgress(progress);

        switch (snapshot.state) {
          case "paused":
            console.log("Upload is paused");
            break;
          case "running":
            console.log("Upload is running");
            break;
          default:
        }
      },
      (error) => {
        // Handle unsuccessful uploads
        console.log(error);
      },
      () => {
        // Handle successful uploads on complete
        // For instance, get the download URL: https://firebasestorage.googleapis.com/...
        getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
          setContentPic(downloadURL);
          setUploading(false);
          dispatch(
            createHeroCarousel({
              projectId: projectId,
              fileType,
              fileName: downloadURL,
              name: file.name,
              duration: duration,
            })
          );
          setFileType("");
          setDuration(0);
          setFile("");
        });
      }
    );
  };

  const setFileFunc = async (val) => {
    setFile(val);
    var reader = new FileReader();
    reader.onload = function () {
      var aud = new Audio(reader.result);
      aud.onloadedmetadata = function () {
        setDuration(aud.duration);
      };
    };
    reader.readAsDataURL(val);
  };

  const submitForm = (e) => {
    e.preventDefault();

    if (duration > 0) {
      imageUploadThumb();
    }
  };

  const changeFileType = (val) => {
    setFile("");
    setTheInputKey(theInputKey + 1);
    setFileType(val);
    setDuration(0);
  };

  return (
    <div style={{ height: "100%", minWidth: "400px", position:"relative" }}>
      <Box
        sx={{
          my: 8,
          mx: 4,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        {/* <motion.div
   
          whileHover={{ scale: "1.1" }}
          whileTap={{ scale: 1 }}
          style={{ position: "absolute", top: ".5rem", right: ".5rem" }}
        >
          <CloseIcon color="error" />
        </motion.div> */}
        <Avatar sx={{ m: 1, bgcolor: "primary.main" }}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Add New Content
        </Typography>

        {uploading ? (
          <Box
            style={{
              width: "100%",
              height: "100%",
            }}
          >
            <CircularLoader progress={progress} />
          </Box>
        ) : (
          <Box
            component="form"
            onSubmit={(e) => submitForm(e)}
            noValidate
            sx={{ mt: 1 }}
          >
            <FormControl fullWidth style={{ gap: "1rem" }}>
              <InputLabel id="demo-simple-select-label">
                Choose File Type
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={fileType}
                label={`You have selected ` + fileType}
                onChange={(e) => changeFileType(e.target.value)}
              >
                <MenuItem value={"image"}>Image</MenuItem>
                <MenuItem value={"video"}>Video</MenuItem>
              </Select>

              <TextField
                type="file"
                ref={fileRef}
                inputProps={{
                  accept: `${
                    fileType === "image"
                      ? "image/*"
                      : "video/mp4,video/mkv,video/x-m4v"
                  }`,
                }}
                key={theInputKey || ""}
                style={{
                  cursor: "pointer",
                  zIndex: "800",
                }}
                onChange={(e) => setFileFunc(e.target.files[0])}
                variant="outlined"
                component="span"
              >
                Upload Content
              </TextField>

              <TextField
                value={duration}
                onChange={(e) => setDuration(e.target.value)}
                type="number"
                disabled={fileType === "video"}
                InputProps={{
                  inputProps: {
                    max: 36000,
                    min: 1,
                  },
                }}
                label="Enter duration in seconds"
              />
            </FormControl>

            <Button
              type="submit"
              fullWidth
              color="primary"
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
              disabled={
                !fileType || (fileType === "image" && duration < 1) || !file
              }
            >
              Add To Project Carousel
            </Button>

            <Copyright sx={{ mt: 5 }} />
          </Box>
        )}
      </Box>
    </div>
  );
};

export default CreateMainCara;
