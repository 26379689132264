
import React from "react";
import { createRoot } from 'react-dom/client';
import ReactDOM from "react-dom";
import { store } from './redux/store'
import { Provider } from 'react-redux'
import App from "./App";
import './index.css'
import * as serviceWorkerRegistration from './serviceWorkerRegistration';


const root = createRoot(document.getElementById('root'));
root.render(

  <Provider store={store}>
    <App />
  </Provider>

);

serviceWorkerRegistration.register();