// ** Redux Imports
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import toast from "react-hot-toast";

// ** Axios Imports
import axios from "axios";



/*=============================================
=            ASYNC THUNK FUNCTIONS            =
=============================================*/

/*=============================================
=                INITIAL STATES              =
=============================================*/

// ** Fetch Authentication

const initialState = {};

// ** FETCH ALL ATTRIBUTES
export const fetchAllAttributes = createAsyncThunk(
  "all-attributes",
  async () => {
    const res = await axios.get("/api/attributes");
    return res.data;
  }
);

// ** CREATE NEW ATTRIBUTES
export const createAttribute = createAsyncThunk(
  "create-attribute",
  async () => {
    const res = await axios.post("/api/attributes");
    return res.data;
  }
);

// ** UPDATE ATTRIBUTES
export const updateAttribute = createAsyncThunk(
  "update-attributes",
  async ({ name, componentType, filterType, attriId,projectType, attributeType }) => {
    const res = await axios.put("/api/attributes/update", {
      name,
      componentType,
      filterType,
      projectType,
      attributeType,
      attriId,
    });
    return res.data;
  }
);

// ** DELETE ATTRIBUTES
export const deleteAttributes = createAsyncThunk("delete-attributes", async (attriIds) => {
  
  const res = await axios.delete(`/api/attributes/delete`,{
    data:{attriIds}
  });
  return res.data
});

/*=====  End of ASYNC THINK FUNCTIONS  ======*/

/*============================================
=             REGISTER EXTRA REDUCERS              =
=============================================*/
function extraReducers(builder) {

    //FETCH ALL ATTRIBUTES
  builder.addCase(fetchAllAttributes.pending, (state, action) => {
    return {
      ...state,
      usersLoading: true,
      isInitialized: true,
    };
  });
  builder.addCase(fetchAllAttributes.fulfilled, (state, action) => {
    return {
      ...state,
      userLoading: false,
      attributes: action.payload,
    };
  });
  builder.addCase(fetchAllAttributes.rejected, (state, action) => {
    return {
      ...state,
      usersLoading: false,
      error: "Failed to fetch userList",
    };
  });


  //CREATE NEW ATTRIBUTES
  builder.addCase(createAttribute.pending, (state, action) => {
    return {
      ...state,
      usersLoading: true,
      isInitialized: false,
    };
  });
  builder.addCase(createAttribute.fulfilled, (state, action) => {
    toast.success("Successfully created a new attribute!");
    return {
      ...state,
      userLoading: false,
      attributes: [...state.attributes, action.payload],
    };
  });
  builder.addCase(createAttribute.rejected, (state, action) => {
    toast.error("Failed to create new user at this time!");
    return {
      ...state,
      usersLoading: false,
      error: "Failed create user",
    };
  });


 //UPDATE ATTRIBUTES
  builder.addCase(updateAttribute.pending, (state, action) => {
    return {
      ...state,
      usersLoading: true,
      isInitialized: false,
    };
  });
  builder.addCase(updateAttribute.fulfilled, (state, action) => {
    toast.success("Successfully updated new attribute!");

  
    return {
      ...state,
      userLoading: false,
      attributes: [...action.payload],
    };
  });
  builder.addCase(updateAttribute.rejected, (state, action) => {
    toast.error("Failed to update attribute!");
    return {
      ...state,
      usersLoading: false,
      error: "Failed to update attribute",
    };
  });


 //DELETE ATTRIBUTES
  builder.addCase(deleteAttributes.pending, (state, action) => {
    return {
      ...state,
      usersLoading: true,
      isInitialized: false,
    };
  });
  builder.addCase(deleteAttributes.fulfilled, (state, action) => {
    toast.success("Successfully deleted attributes!");
    return {
      ...state,
      userLoading: false,
     attributes:[ ...action.payload],
    };
  });
  builder.addCase(deleteAttributes.rejected, (state, action) => {
    toast.error("Failed to delete attributes at this time!");
    return {
      ...state,
      usersLoading: false,
      error: "Failed to delete attributes",
    };
  });
}

export const attributeSlice = createSlice({
  name: "attributes",
  initialState,
  reducers: {
   
  },
  extraReducers,
});

// Action creators are generated for each case reducer function
export const {} = attributeSlice.actions;

export default attributeSlice.reducer;
