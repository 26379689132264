// ** Redux Imports
import toast from "react-hot-toast";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";

/*=============================================
=                INITIAL STATES              =
=============================================*/
const themeDataJson = JSON.parse(localStorage.getItem("themeData"));

const initialState = {
  palette: {
    primary: {
      light: "#5454e1",
      main: "#1e5e9d",
      dark: "#093561",
      background: "#ffffff",
      text: "#000000",
    },
    navbar: {
      light: "#5454e1",
      main: "rgba(33, 43, 54, 0.90)",
      dark: "#093561",
      background: "#ffffff",
      text: "rgba(255, 255, 255, 0.50)",
      selected: "rgba(255, 255, 255, 0.10)",
    },
    secondary: {
      light: "#d4939e",
      main: "#093561",
      dark: "#040202",
      background: "#ffffff",
      text: "#000000",
    },
    seaty: {
      light: "#ffffff",
      main: "#0ef36e",
      dark: "#054e00",
      background: "#0fb800",
      text: "#000000",
    },
    mode: "light",
  },
  themeData: themeDataJson ? themeDataJson : [],
  themes: [],
};

/*=============================================
=            ASYNC THUNK FUNCTIONS            =
=============================================*/
// ** GET THEME
export const getAllThemes = createAsyncThunk("getall-themes", async () => {
  const res = await axios.get(`/api/themes`);
  return res.data;
});

// ** GET THEME
export const createNewTheme = createAsyncThunk(
  "create-theme",
  async (themeData) => {
    const res = await axios.post(`/api/themes/create`, {
      themeData,
    });
    return res.data;
  }
);

// ** GET THEME
export const getTheme = createAsyncThunk("get-theme", async (projectId) => {
  const res = await axios.get(`/api/themes/get/${projectId}`);
  return res.data;
});

// ** UPDATE THEME
export const updateTheme = createAsyncThunk(
  "update-theme",
  async (themeData) => {
    const res = await axios.post("/api/themes/update", {
      themeData,
    });

    return res.data;
  }
);
// ** UPDATE THEME
export const updateNewTheme = createAsyncThunk(
  "update-new-theme",
  async ({ themeData }, { dispatch }) => {
    const res = await axios.put("/api/themes/newupdate", {
      themeData,
    });
    dispatch(getAllThemes());
    return res.data;
  }
);

// ** UPDATE THEME
export const deleteTemplate = createAsyncThunk(
  "delete-theme",
  async (themeData) => {
    const res = await axios.delete("/api/themes/delete", {
      data: themeData,
    });

    return res.data;
  }
);

// ** GET ALL SEATY TEMPLATES
export const getAllSeatyTemplates = createAsyncThunk(
  "getall-seatytemp",
  async () => {
    const res = await axios.get("/api/themes/allseatythemes");

    return res.data;
  }
);

// ** DUPLICATE THEMES
export const duplicateTheme = createAsyncThunk(
  "duplicateTheme",
  async ({ themeId }) => {
    const res = await axios.post("/api/themes/duplicate",{themeId});

    return res.data;
  }
);

function extraReducers(builder) {
  //CREATE THEME
  builder.addCase(getAllThemes.pending, (state, action) => {
    return {
      ...state,
      loading: true,
      isInitialized: true,
    };
  });
  builder.addCase(getAllThemes.fulfilled, (state, action) => {
    return {
      ...state,
      themes: action.payload,
    };
  });
  builder.addCase(getAllThemes.rejected, (state, action) => {
    toast.error("An error has occured trying to fetch Themes list");
  });

  //CREATE THEME
  builder.addCase(createNewTheme.pending, (state, action) => {
    return {
      ...state,
      loading: true,
      isInitialized: true,
    };
  });
  builder.addCase(createNewTheme.fulfilled, (state, action) => {
    return {
      ...state,
      themes: [...state.themes, action.payload],
    };
  });
  builder.addCase(createNewTheme.rejected, (state, action) => {
    toast.error("An error has occured trying to create a theme");
  });

  //UPDATE THEME
  builder.addCase(updateTheme.pending, (state, action) => {
    return {
      ...state,
      loading: true,
      isInitialized: true,
    };
  });
  builder.addCase(updateTheme.fulfilled, (state, action) => {
    toast.success("You have saved your theme!");
    localStorage.setItem("themeData", JSON.stringify(action.payload));
    return {
      ...state,
      themeData: action.payload,
    };
  });
  builder.addCase(updateTheme.rejected, (state, action) => {
    toast.error("An error has occured trying to save the theme");
  });

  //////////////////////////////////////////////////////////////////////

  //UPDATE THEME
  builder.addCase(updateNewTheme.pending, (state, action) => {
    return {
      ...state,
      loading: true,
      isInitialized: true,
    };
  });
  builder.addCase(updateNewTheme.fulfilled, (state, action) => {
    toast.success("You have saved your theme!");
    localStorage.setItem("themeData", JSON.stringify(action.payload));
    return {
      ...state,
      template: action.payload,
    };
  });
  builder.addCase(updateNewTheme.rejected, (state, action) => {
    toast.error("An error has occured trying to save the theme");
  });

  //////////////////////////////////////////////////////////////////////

  //CREATE PROJECTS
  builder.addCase(getTheme.pending, (state, action) => {
    return {
      ...state,
      loading: true,
      isInitialized: true,
    };
  });
  builder.addCase(getTheme.fulfilled, (state, action) => {
    localStorage.setItem("themeData", JSON.stringify(action.payload));

    return {
      ...state,
      themeData: action.payload,
    };
  });
  builder.addCase(getTheme.rejected, (state, action) => {
    toast.error("An error has occured trying to save the theme");
  });

  //DELETE
  builder.addCase(deleteTemplate.pending, (state, action) => {
    return {
      ...state,
      loading: true,
      isInitialized: true,
    };
  });
  builder.addCase(deleteTemplate.fulfilled, (state, action) => {
    toast.success("Successfull deleted templates");
    return {
      ...state,
      themes: action.payload,
    };
  });
  builder.addCase(deleteTemplate.rejected, (state, action) => {
    toast.error("An error has occured trying to delete templates");
  });

  //GET ALL SEATY TEMPLATES
  builder.addCase(getAllSeatyTemplates.pending, (state, action) => {
    return {
      ...state,
      loading: true,
      isInitialized: true,
    };
  });
  builder.addCase(getAllSeatyTemplates.fulfilled, (state, action) => {
    return {
      ...state,
      seatyTemplates: action.payload,
    };
  });
  builder.addCase(getAllSeatyTemplates.rejected, (state, action) => {
    toast.error("An error has occured trying to delete templates");
  });

  //GET ALL SEATY TEMPLATES
  builder.addCase(duplicateTheme.pending, (state, action) => {
    return {
      ...state,
      loading: true,
      isInitialized: true,
    };
  });
  builder.addCase(duplicateTheme.fulfilled, (state, action) => {
    toast.success("Successfull updated theme");
    return {
      ...state,
      themes: [...state.themes, action.payload],
    };
  });
  builder.addCase(duplicateTheme.rejected, (state, action) => {
    toast.error("An error has occured trying to delete templates");
  });
}

export const themeSlice = createSlice({
  name: "theme",
  initialState,
  reducers: {
    changeTheme: (state, action) => {
      return {
        ...state,
        palette: [...action.payload],
      };
    },
    changeEditChoice: (state, action) => {
      return {
        ...state,
        template: action.payload,
      };
    },
  },
  extraReducers,
});

export const { selectUsersId, changeEditChoice } = themeSlice.actions;
// Action creators are generated for each case reducer function
// export const { } = authSlice.actions;

export default themeSlice.reducer;
