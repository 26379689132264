// ** Redux Imports
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import toast from "react-hot-toast";

// ** Axios Imports
import axios from "axios";
import CreateMainCara from "../../components/createMainCara/CreateMainCara";

/*=============================================
=                INITIAL STATES              =
=============================================*/
const selectedPro = JSON.parse(localStorage.getItem("selectedPro"));
const chosenLocal = JSON.parse(localStorage.getItem("chosenProject"));
// const carouselLocal = JSON.parse(localStorage.getItem("carousel"));

const initialState = {
  project: "",
  loading: "",
  success: "",
  error: "",
  projects: [],
  userProjects: selectedPro ? [...selectedPro] : [],
  userProjectId: "",
  chosenProject: chosenLocal ? chosenLocal : [],
  // carousel: carouselLocal ? [carouselLocal] : [],
};

/*=============================================
=            ASYNC THUNK FUNCTIONS            =
=============================================*/

////////////////////////////////////////////////////////BUILDING CAROUSEL START///////////////////////////////////////////////////////
// ** CREATE Carousel
export const getAllCara = createAsyncThunk(
  "get-all-cara",
  async ({ projectId }) => {
    const res = await axios.post(`/api/projects//allCara/${projectId}`);

    return res.data;
  }
);

// ** CREATE Carousel
export const createCarousel = createAsyncThunk(
  "create-Carousel",
  async ({ projectId, fileType, name, duration, fileName }) => {
    const res = await axios.post("/api/projects/addcarousel", {
      projectId,
      fileType,
      name,
      duration,
      fileName,
    });

    return res.data;
  }
);

// ** DELETE CAROUSEL
export const deleteCarousels = createAsyncThunk(
  "deleteCara",
  async ({ caraId, projectId }) => {
    let res = await axios.delete(`/api/projects/deletecarousel`, {
      data: { projectId, caraId },
    });

    return res.data;
  }
);

// ** DELETE SINGLE CARAOUSEL
export const deleteSingleCarousel = createAsyncThunk(
  "delete-single-Cara",
  async ({ caraId, projectId }) => {
    let res = await axios.delete(`/api/projects/deletesinglecara`, {
      data: { projectId, caraId },
    });

    return res.data;
  }
);

// ** DELETE CONTENT
export const deleteContent = createAsyncThunk(
  "delete-Content",
  async ({ caraId, projectId }) => {
    let res = await axios.put(`/api/projects//contentdelete`, {
      projectId,
      caraId,
    });

    return res.data;
  }
);

// ** UPDATE CAROUSELS
export const updateCarousels = createAsyncThunk(
  "updateCara",
  async ({ caraId, projectId, fileName }) => {
    let res = await axios.put(`/api/projects/update/carasol`, {
      projectId,
      caraId,
      fileName,
    });
    return res.data;
  }
);

// ** RE ORDER CARA
export const reOrderCara = createAsyncThunk(
  "reOrderCara",
  async ({ caraData, projectId }) => {
    let res = await axios.put(`/api/projects/carareorder`, {
      caraData,
      projectId,
    });
    return res.data;
  }
);

//////////////////////////////////////////////////////BUILDING CAROUSEL END/////////////////////////////////////////////////////////

// ** CRREATE PROJECT
export const createProject = createAsyncThunk(
  "create-project",
  async ({ projectName, accountId, projectType, planType }) => {
    let apiEndpoint;

    // Determine the API endpoint based on projectType
    if (projectType === "Building") {
      apiEndpoint = "/api/projects/create";
    } else if (projectType === "Builders") {
      apiEndpoint = "/api/builders/projects/create";
    } else {
      // If projectType is neither "type1" nor "type2", you can handle the error here.
      apiEndpoint = "/api/projects/create";
    }

    const res = await axios.post(apiEndpoint, {
      projectName,
      accountId,
      projectType,
      planType,
    });

    return res.data;
  }
);

export const updateProjectPage = createAsyncThunk(
  "update-project-builder",
  async (
    {
      projectId,
      projectName,
      parent,
      parentId,
      navbarCategories,
      amenities,
      categories,
      floorplans,
      attributes,
      filters,
      theme,
      mapData,
      lat,
      lng,
      backImage,
      backColor,
      logo,
      altLogo,
      galleryImages,
      mainPic,
      address,
      desc,
      status,
      statusColor,
    },
    { dispatch }
  ) => {
    const res = await axios.put("/api/builders/projects/update", {
      projectId,
      projectName,
      parent,
      parentId,
      navbarCategories,
      amenities,
      categories,
      floorplans,
      attributes,
      filters,
      theme,
      mapData,
      lat,
      lng,
      backImage,
      backColor,
      logo,
      altLogo,
      galleryImages,
      mainPic,
      address,
      desc,
      status,
      statusColor,
    });
    return res.data;
  }
);

// ** FETCH DEAL MEMO LIST
export const fetchAllProjects = createAsyncThunk(
  "allProjects",
  async ({ accountId }) => {
    const res = await axios.get(`/api/projects/fetch/${accountId}`);
    return res.data;
  }
);

export const deleteProjects = createAsyncThunk(
  "deleteProjects",
  async ({ projectId, projectType }) => {
    const apiEndpoint = `/api/projects/delete`;
    const res = await axios.delete(apiEndpoint, {
      data: {
        projectId: projectId,
        projectType: projectType,
      },
    });

    return res.data;
  }
);

// // ** UPDATE PROEJECT SCREEN
export const updateProjectScreens = createAsyncThunk(
  "updatetheprojects",
  async (projectId) => {
    const res = await axios.put(`/api/projects/update/project`, {
      projectId,
    });
    return res.data;
  }
);
////////////////////////////////////////////////////////////////////////PROJECT END////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////MAIN HERO CAROUSEL START/////////////////////////////////////////////////////////////////

// ** CREATE PROJECT
export const createHeroCarousel = createAsyncThunk(
  "Create-Hero-Carousel",
  async ({ projectId, fileType, name, duration, fileName }) => {
    const res = await axios.post("/api/builders/projects/maincara/add", {
      projectId,
      fileType,
      name,
      duration,
      fileName,
    });

    return res.data;
  }
);

export const removeHeroCarousel = createAsyncThunk(
  "Remove-Hero-Carousel",
  async ({ projectId, caraId }) => {
    const res = await axios.data("/api/builders/projects/maincara/delete", {
      projectId,
      caraId,
    });

    return res.data;
  }
);
//////////////////////////////////////////////////////////////////MAIN HERO CAROUSEL END/////////////////////////////////////////////////////////////////

//////////////////////////////////////////////////////////////////MAP DATA START/////////////////////////////////////////////////////////////////

//CREATE MAP DATA CALL
export const createMap = createAsyncThunk(
  "Create-Map-Data",
  async ({ projectId }) => {
    const res = await axios.post("/api/builders/projects/mapdata/add", {
      projectId,
    });

    return res.data;
  }
);

//DELETE MAP DATA CALL
export const deleteMapData = createAsyncThunk(
  "delete-Map-Data",
  async ({ projectId, mapId }) => {
    const res = await axios.delete("/api/builders/projects/mapdata/delete", {
      data: { projectId, mapId },
    });

    return res.data;
  }
);

//UPDATE MAP DATA CALL
export const updateMapData = createAsyncThunk(
  "update-Map-Data",
  async ({ projectId, mapId, mapData }) => {
    const res = await axios.put("/api/builders/projects/mapdata/update", {
      projectId,
      mapId,
      mapData,
    });

    return res.data;
  }
);

/////////////////////////////////////////////////////////////////MAP DATA END/////////////////////////////////////////////////////////////////

//////////////////////////////////////////////////////////////////IMAGE GALLERY START /////////////////////////////////////////////////////////////////
//CREATE IMAGE DATA CALL
export const createImage = createAsyncThunk(
  "Create-Image-Data",
  async ({ projectId }) => {
    const res = await axios.post("/api/builders/projects/gallery/add", {
      projectId,
    });

    return res.data;
  }
);

//DELETE MAP DATA CALL
export const deleteImage = createAsyncThunk(
  "delete-Image-Data",
  async ({ projectId, imageId }) => {
    const res = await axios.delete("/api/builders/projects/gallery/delete", {
      data: { projectId, imageId },
    });

    return res.data;
  }
);

//UPDATE MAP DATA CALL
export const updateImage = createAsyncThunk(
  "update-Image-Data",
  async ({ projectId, imageId, imageData }) => {
    console.log(projectId, imageId, imageData);
    const res = await axios.put("/api/builders/projects/gallery/update", {
      projectId,
      imageId,
      imageData,
    });

    return res.data;
  }
);

//////////////////////////////////////////////////////////////////IMAGE GALLERY END/////////////////////////////////////////////////////////////////

//////////////////////////////////////////////////////////////////Create custom page /////////////////////////////////////////////////////////////////

export const createCustomPage = createAsyncThunk(
  "create-custom-page",
  async ({ projectId, name,type, invisible }) => {
    const res = await axios.post("/api/builders/projects/createpage", {
      projectId,
      name,
      type,
      invisible
    });

    return res.data;
  }
);

export const saveCustomPage = createAsyncThunk(
  "save-custom-page",
  async ({ projectId, title, page }) => {
    const res = await axios.put("/api/builders/projects/savepage", {
      projectId,
      title,
      page,
    });

    return res.data;
  }
);

export const deleteCustomPage = createAsyncThunk(
  "delete-custom-page",
  async ({ projectId, pageId }) => {
    const res = await axios.delete("/api/builders/projects/deletecustompage", {
      data: { projectId, pageId },
    });

    return res.data;
  }
);

//////////////////////////////////////////////////////////////////SVG CUSTOM MAPS /////////////////////////////////////////////////////////////////

export const addNewSvgMap = createAsyncThunk(
  "add-new-svg-map",
  async ({ projectId, name, label, map, screenId }) => {
    const res = await axios.post("/api/projects/svgmap/add", {
      screenId,
      projectId,
      name,
      label,
      map,
    });

    return res.data;
  }
);

//REMOVE SVG MAPS
export const removeSvgMaps = createAsyncThunk(
  "remove-svg-map",
  async ({ projectId, mapIds }) => {
    const res = await axios.delete("/api/projects/svgmap/remove", {data:{projectId,
      mapIds}
    });
    return res.data;
  }
);

//UPDATE SVG MAPS
export const updateSvgMaps = createAsyncThunk(
  "update-svg-map",
  async ({ projectId, name, label, map,fabric, mapId}) => {
    const res = await axios.put("/api/projects/svgmap/update", {
      projectId,
      name,
      label,
      map,
      fabric, mapId
    });
    return res.data;
  }
);












//////////////////////////////////////////////////////////////////CHANGE DATE/////////////////////////////////////////////////////////////////
export const changeDate = createAsyncThunk(
  "addnewDate",
  async ({ projectId, accountId, date }) => {
    const res = await axios.post("/api/projects/date", {
      projectId,
      accountId,
      date,
    });
    return res.data;
  }
);






/*=====  End of ASYNC THINK FUNCTIONS  ======*/

/*============================================
=             REGISTER EXTRA REDUCERS              =
=============================================*/
function extraReducers(builder) {
  //CREATE PROJECTS
  builder.addCase(createProject.pending, (state, action) => {
    return {
      ...state,
      loading: true,
      isInitialized: true,
    };
  });
  builder.addCase(createProject.fulfilled, (state, action) => {
    toast.success("You have successfully created a Project!");

    return {
      ...state,
      projects: [...state.projects, action.payload],
    };
  });
  builder.addCase(createProject.rejected, (state, action) => {
    toast.error("Invalid Project Entry (make sure your ids are unique)");
    return {
      ...state,
      loading: false,
    };
  });

  //GET ALL CARA THAT MATCHES PROJECT ID
  builder.addCase(getAllCara.pending, (state, action) => {
    return {
      ...state,
      loading: true,
      isInitialized: true,
    };
  });
  builder.addCase(getAllCara.fulfilled, (state, action) => {

    localStorage.setItem("carousel", JSON.stringify(action.payload));
    return {
      ...state,
      chosenProject: { ...state.chosenProject, carousel: action.payload },
      
    };
  });
  builder.addCase(getAllCara.rejected, (state, action) => {
    toast.error("Cannot create carousel at this time)");
    return {
      ...state,
      loading: false,
    };
  });
  //CREATE Carasol
  builder.addCase(createCarousel.pending, (state, action) => {
    return {
      ...state,
      loading: true,
      isInitialized: true,
    };
  });
  builder.addCase(createCarousel.fulfilled, (state, action) => {
    toast.success("You have successfully created a carousel Item!");
    localStorage.setItem("carousel", JSON.stringify(action.payload));
    return {
      ...state,
      chosenProject: { ...state.chosenProject, carousel: action.payload },
    };
  });
  builder.addCase(createCarousel.rejected, (state, action) => {
    toast.error("Cannot create carousel at this time)");
    return {
      ...state,
      loading: false,
    };
  });

  //Delete Carasol
  builder.addCase(deleteCarousels.pending, (state, action) => {
    return {
      ...state,
      loading: true,
      isInitialized: true,
    };
  });
  builder.addCase(deleteCarousels.fulfilled, (state, action) => {
    toast.success("You have successfully deleted a carousel Item!");
    localStorage.setItem("carousel", JSON.stringify(action.payload));

    return {
      ...state,
      chosenProject: action.payload,
    };
  });
  builder.addCase(deleteCarousels.rejected, (state, action) => {
    toast.error("Cannot delete carousel at this time)");
    return {
      ...state,
      loading: false,
    };
  });

  //Update Carasol
  builder.addCase(updateCarousels.pending, (state, action) => {
    return {
      ...state,
      loading: true,
      isInitialized: true,
    };
  });
  builder.addCase(updateCarousels.fulfilled, (state, action) => {
    toast.success("You have successfully updated a carousel Item!");
    localStorage.setItem("carousel", JSON.stringify(action.payload));
    return {
      ...state,
      carousel: [...action.payload],
    };
  });
  builder.addCase(updateCarousels.rejected, (state, action) => {
    toast.error("Cannot update carousel at this time)");
    return {
      ...state,
      loading: false,
    };
  });

  //Update Carasol
  builder.addCase(deleteSingleCarousel.pending, (state, action) => {
    return {
      ...state,
      loading: true,
      isInitialized: true,
    };
  });
  builder.addCase(deleteSingleCarousel.fulfilled, (state, action) => {
    toast.success("You have successfully removed Carousel item");
    localStorage.setItem("carousel", JSON.stringify(action.payload));
    return {
      ...state,
      carousel: [...action.payload],
    };
  });
  builder.addCase(deleteSingleCarousel.rejected, (state, action) => {
    toast.error("Cannot remove Carousel item at this time)");
    return {
      ...state,
      loading: false,
    };
  });

  //Delete Carsol Pic
  builder.addCase(deleteContent.pending, (state, action) => {
    return {
      ...state,
      loading: true,
      isInitialized: true,
    };
  });
  builder.addCase(deleteContent.fulfilled, (state, action) => {
    toast.success("You have successfully removed content");
    localStorage.setItem("carousel", JSON.stringify(action.payload));
    return {
      ...state,
      carousel: [...action.payload],
    };
  });
  builder.addCase(deleteContent.rejected, (state, action) => {
    toast.error("Cannot remove content at this time)");
    return {
      ...state,
      loading: false,
    };
  });

  //FETCH ALL PROJECTS
  builder.addCase(fetchAllProjects.pending, (state, action) => {
    return {
      ...state,
      loadingProjects: true,
    };
  });
  builder.addCase(fetchAllProjects.fulfilled, (state, action) => {
    return {
      ...state,
      loadingProjects: false,
      projects: [...action.payload],
    };
  });
  builder.addCase(fetchAllProjects.rejected, (state, action) => {
    return {
      ...state,
      loading: false,
      projectsError: "Error fetching products",
    };
  });

  //DELETE PROJECT
  builder.addCase(deleteProjects.pending, (state, action) => {
    return {
      ...state,
      loadingProjects: true,
    };
  });
  builder.addCase(deleteProjects.fulfilled, (state, action) => {
    console.log(action.payload);
    toast.success("Successfully deleted project");
    return {
      ...state,
      projects: [...state.projects.filter((p) => p._id !== action.payload)],
    };
  });
  builder.addCase(deleteProjects.rejected, (state, action) => {
    toast.error("Could not delete project at this time!");
    return {
      ...state,
      loading: false,
      projectsError: "Error fetching products",
    };
  });

  //UPDATE PROJECT SCREENS
  builder.addCase(updateProjectScreens.pending, (state, action) => {
    return {
      ...state,
      loadingProjects: true,
    };
  });
  builder.addCase(updateProjectScreens.fulfilled, (state, action) => {
    toast.success("Update Initiated");
    // return {
    //   ...state, projects: [...state.projects.filter((p) => p._id !== action.payload)],
    // };
  });
  builder.addCase(updateProjectScreens.rejected, (state, action) => {
    toast.error("Could not update screens at this time.");
  });

  //REORDER PROJECT SCREENS
  builder.addCase(reOrderCara.pending, (state, action) => {
    return {
      ...state,
      loadingProjects: true,
    };
  });
  builder.addCase(reOrderCara.fulfilled, (state, action) => {
    toast.success("Re-order Successfull");
    localStorage.setItem("chosenProject", JSON.stringify(action.payload));
    return {
      ...state,
      chosenProject: action.payload,
    };
  });
  builder.addCase(reOrderCara.rejected, (state, action) => {
    toast.error("Could not update screens at this time.");
  });

  //UPDATE PROJECT
  builder.addCase(updateProjectPage.pending, (state, action) => {
    return {
      ...state,
      loadingProjects: true,
    };
  });
  builder.addCase(updateProjectPage.fulfilled, (state, action) => {
    toast.success("Updated Project Successfully");
    localStorage.setItem("chosenProject", JSON.stringify(action.payload));
    return {
      ...state,
      chosenProject: action.payload,
    };
  });
  builder.addCase(updateProjectPage.rejected, (state, action) => {
    toast.error("Could not update screens at this time.");
  });

  ////////////////////////////////////////HERO CAROUSEL ////////////////////////////
  //CREATE HERO CAROUSEL

  builder.addCase(createHeroCarousel.pending, (state, action) => {
    return {
      ...state,
      loadingProjects: true,
    };
  });
  builder.addCase(createHeroCarousel.fulfilled, (state, action) => {
    toast.success("Successfully added Hero Content to Project");
    localStorage.setItem("chosenProject", JSON.stringify(action.payload));
    return {
      ...state,
      chosenProject: action.payload,
    };
  });
  builder.addCase(createHeroCarousel.rejected, (state, action) => {
    toast.error("Could not update screens at this time.");
  });

  ////////////////////////////////////////MAP DATA SECTION ////////////////////////////

  builder.addCase(createMap.pending, (state, action) => {
    return {
      ...state,
      loadingProjects: true,
    };
  });
  builder.addCase(createMap.fulfilled, (state, action) => {
    toast.success("Successfully added MapData to Project");
    localStorage.setItem("chosenProject", JSON.stringify(action.payload));
    return {
      ...state,
      chosenProject: action.payload,
    };
  });
  builder.addCase(createMap.rejected, (state, action) => {
    toast.error("Could not add map data at this time.");
  });

  builder.addCase(deleteMapData.pending, (state, action) => {
    return {
      ...state,
      loadingProjects: true,
    };
  });
  builder.addCase(deleteMapData.fulfilled, (state, action) => {
    toast.success("Successfully deleted MapData to Project");
    localStorage.setItem("chosenProject", JSON.stringify(action.payload));
    return {
      ...state,
      chosenProject: action.payload,
    };
  });
  builder.addCase(deleteMapData.rejected, (state, action) => {
    toast.error("Could not delete mapdata at this time.");
  });

  builder.addCase(updateMapData.pending, (state, action) => {
    return {
      ...state,
      loadingProjects: true,
    };
  });
  builder.addCase(updateMapData.fulfilled, (state, action) => {
    toast.success("Successfully updated MapData in Project");
    localStorage.setItem("chosenProject", JSON.stringify(action.payload));
    return {
      ...state,
      chosenProject: action.payload,
    };
  });
  builder.addCase(updateMapData.rejected, (state, action) => {
    toast.error("Could not update mapdata at this time.");
  });

  ////////////////////////////////////////MAP DATA SECTION ////////////////////////////

  builder.addCase(createImage.pending, (state, action) => {
    return {
      ...state,
      loadingProjects: true,
    };
  });
  builder.addCase(createImage.fulfilled, (state, action) => {
    toast.success("Successfully added image into Project");
    localStorage.setItem("chosenProject", JSON.stringify(action.payload));
    return {
      ...state,
      chosenProject: action.payload,
    };
  });
  builder.addCase(createImage.rejected, (state, action) => {
    toast.error("Could not add image at this time.");
  });

  builder.addCase(deleteImage.pending, (state, action) => {
    return {
      ...state,
      loadingProjects: true,
    };
  });
  builder.addCase(deleteImage.fulfilled, (state, action) => {
    toast.success("Successfully deleted Image");
    localStorage.setItem("chosenProject", JSON.stringify(action.payload));
    return {
      ...state,
      chosenProject: action.payload,
    };
  });
  builder.addCase(deleteImage.rejected, (state, action) => {
    toast.error("Could not delete image at this time.");
  });

  builder.addCase(updateImage.pending, (state, action) => {
    return {
      ...state,
      loadingProjects: true,
    };
  });
  builder.addCase(updateImage.fulfilled, (state, action) => {
    toast.success("Successfully updated Image");
    localStorage.setItem("chosenProject", JSON.stringify(action.payload));
    return {
      ...state,
      chosenProject: action.payload,
    };
  });
  builder.addCase(updateImage.rejected, (state, action) => {
    toast.error("Could not update image at this time.");
  });

  //CREATE CUSTOM PAGE

  builder.addCase(createCustomPage.pending, (state, action) => {
    return {
      ...state,
      loadingProjects: true,
    };
  });
  builder.addCase(createCustomPage.fulfilled, (state, action) => {
    toast.success("Successfully Created Page");
    localStorage.setItem("chosenProject", JSON.stringify(action.payload));
    return {
      ...state,
      chosenProject: action.payload,
    };
  });
  builder.addCase(createCustomPage.rejected, (state, action) => {
    toast.error("Could not create page at this time.");
  });

  //SAVE CUSTOM PAGE

  builder.addCase(saveCustomPage.pending, (state, action) => {
    return {
      ...state,
      loadingProjects: true,
    };
  });
  builder.addCase(saveCustomPage.fulfilled, (state, action) => {
    toast.success("Successfully Updated Page");
    localStorage.setItem("chosenProject", JSON.stringify(action.payload));
    return {
      ...state,
      chosenProject: action.payload,
    };
  });
  builder.addCase(saveCustomPage.rejected, (state, action) => {
    toast.error("Could not update page at this time.");
  });

  //DELETE CUSTOM PAGE

  builder.addCase(deleteCustomPage.pending, (state, action) => {
    return {
      ...state,
      loadingProjects: true,
    };
  });
  builder.addCase(deleteCustomPage.fulfilled, (state, action) => {
    toast.success("Successfully Deleted Page");
    localStorage.setItem("chosenProject", JSON.stringify(action.payload));
    return {
      ...state,
      chosenProject: action.payload,
    };
  });
  builder.addCase(deleteCustomPage.rejected, (state, action) => {
    toast.error("Could not delete page at this time.");
  });
  //////////////////////////////////////////////////////////////////////SVG MAPS/////////////////////////////////////////////////////////////////////
  //ADD NEW SVG MAP

  builder.addCase(addNewSvgMap.pending, (state, action) => {
    return {
      ...state,
      loadingProjects: true,
    };
  });
  builder.addCase(addNewSvgMap.fulfilled, (state, action) => {
    toast.success("Successfully added new page");
    localStorage.setItem("chosenProject", JSON.stringify(action.payload));
    return {
      ...state,
      chosenProject: action.payload,
    };
  });
  builder.addCase(addNewSvgMap.rejected, (state, action) => {
    toast.error("Could not add map at this time.");
  });

  //REMOVE SVG MAPS

  builder.addCase(removeSvgMaps.pending, (state, action) => {
    return {
      ...state,
      loadingProjects: true,
    };
  });
  builder.addCase(removeSvgMaps.fulfilled, (state, action) => {
    toast.success("Successfully removed Maps from project");
    localStorage.setItem("chosenProject", JSON.stringify(action.payload));
    return {
      ...state,
      chosenProject: action.payload,
    };
  });
  builder.addCase(removeSvgMaps.rejected, (state, action) => {
    toast.error("Could not remove map at this time.");
  });


  //UPDATE SVG MAPS
  builder.addCase(updateSvgMaps.pending, (state, action) => {
    return {
      ...state,
      loadingProjects: true,
    };
  });
  builder.addCase(updateSvgMaps.fulfilled, (state, action) => {
    toast.success("Successfully updated maps from project");
    localStorage.setItem("chosenProject", JSON.stringify(action.payload));
    return {
      ...state,
      chosenProject: action.payload,
    };
  });
  builder.addCase(updateSvgMaps.rejected, (state, action) => {
    toast.error("Could not remove map at this time.");
  });

  //////////////////////////////////////////////////////////////////////SVG MAPS/////////////////////////////////////////////////////////////////////


  // //REMOVE SVG MAPS

  // builder.addCase(removeSvgMaps.pending, (state, action) => {
  //   return {
  //     ...state,
  //     loadingProjects: true,
  //   };
  // });
  // builder.addCase(removeSvgMaps.fulfilled, (state, action) => {
  //   toast.success("Successfully removed Maps from project");
  //   localStorage.setItem("chosenProject", JSON.stringify(action.payload));
  //   return {
  //     ...state,
  //     chosenProject: action.payload,
  //   };
  // });
  // builder.addCase(removeSvgMaps.rejected, (state, action) => {
  //   toast.error("Could not remove map at this time.");
  // });


  // //UPDATE SVG MAPS
  // builder.addCase(updateSvgMaps.pending, (state, action) => {
  //   return {
  //     ...state,
  //     loadingProjects: true,
  //   };
  // });
  // builder.addCase(updateSvgMaps.fulfilled, (state, action) => {
  //   toast.success("Successfully updated maps from project");
  //   localStorage.setItem("chosenProject", JSON.stringify(action.payload));
  //   return {
  //     ...state,
  //     chosenProject: action.payload,
  //   };
  // });
  // builder.addCase(updateSvgMaps.rejected, (state, action) => {
  //   toast.error("Could not remove map at this time.");
  // });

  //////////////////////////////////////////////////////////////////////FABRIC/////////////////////////////////////////////////////////////////////
  //CHANGE DATE
  builder.addCase(changeDate.pending, (state, action) => {
    return {
      ...state,
      loadingProjects: true,
    };
  });
  builder.addCase(changeDate.fulfilled, (state, action) => {
    toast.success("Successfully changed date");

    return {
      ...state,
      dateChangeUpload: action.payload,
    };
  });
  builder.addCase(changeDate.rejected, (state, action) => {
    toast.error("Could not update date at this time");
  });
}

export const projectSlice = createSlice({
  name: "project",
  initialState,
  reducers: {
    selectUsersId: (state, action) => {
      const newVal = [
        ...state.projects.filter(
          (p) => p.userId.toString() === action.payload.toString()
        ),
      ];
      localStorage.setItem("selectedPro", JSON.stringify(newVal));
      return {
        ...state,
        userProjectId: action.payload,
        userProjects: [
          ...state.projects.filter(
            (p) => p.userId.toString() === action.payload.toString()
          ),
        ],
      };
    },

    chooseProjectForExtra: (state, action) => {
      localStorage.setItem("chosenProject", JSON.stringify(action.payload));
      return {
        ...state,
        chosenProject: action.payload,
      };
    },
    removeProjectForExtra: (state, action) => {
      localStorage.removeItem("chosenProject");
      return {
        ...state,
        chosenProject: [],
      };
    },
    removeChangeDate: (state, action) => {
      return {
        ...state,
        dateChangeUpload: "",
      };
    },
  },
  extraReducers,
});

export const {
  selectUsersId,
  chooseProjectForExtra,
  removeProjectForExtra,
  removeChangeDate,
} = projectSlice.actions;
// Action creators are generated for each case reducer function
// export const { } = authSlice.actions;

export default projectSlice.reducer;
