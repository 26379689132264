// ** Redux Imports
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import toast from "react-hot-toast";

// ** Axios Imports
import axios from "axios";

/*=============================================
=                INITIAL STATES              =
=============================================*/

const initialState = {
  units: [],
  currentSuite: [],
  screens: [],
  tennants: [],
};

/*=============================================
=            ASYNC THUNK FUNCTIONS            =
=============================================*/

// ** CREATE NEW TENANT FOR SEATY
export const createNewTennant = createAsyncThunk(
  "create-tennant",
  async ({ tennantData }, { rejectWithValue }) => {
    try {
      const res = await axios.post(`/api/tennants/create`, {
        tennantData,
      });
      return res.data;
    } catch (err) {
      if (axios.isAxiosError(err) && err.response) {
        return rejectWithValue({ data: err.response.data.ERROR });
      }
    }
  }
);

// ** FETCH ALL TENANTS
export const getAllTennants = createAsyncThunk(
  "get-all-tennants",
  async ({ projectId }, { rejectWithValue }) => {
    try {
      const res = await axios.get(`/api/tennants/getbyProject/${projectId}`);
      return res.data;
    } catch (err) {
      if (axios.isAxiosError(err) && err.response) {
        return rejectWithValue({ data: err.response.data.ERROR });
      }
    }
  }
);

// ** DELETE TENANT
export const deleteTenant = createAsyncThunk(
  "deleteTenant",
  async ({ tenantIds,projectId}) => {

    const res = await axios.delete(`/api/tennants/delete`, { data:{ tenantIds, projectId} });
    return res.data;
  }
);
export const updateTennant = createAsyncThunk(
  "update-tennant",
  async ({ tennantData }, { rejectWithValue }) => {
    try {
      const res = await axios.put(`/api/tennants/updatetenant`, {
        tennantData,
      });
      return res.data;
    } catch (err) {
      if (axios.isAxiosError(err) && err.response) {
        return rejectWithValue({ data: err.response.data.ERROR });
      }
    }
  }
);

// ** SEED TENANT DATABASE
export const seedTenantDataBase = createAsyncThunk(
  "seed-tenant-database",
  async ({ projects }, { rejectWithValue }) => {
    try {
      const res = await axios.post(`/api/tennants/seed`, {
        projects,
      });
      return res.data;
    } catch (err) {
      if (axios.isAxiosError(err) && err.response) {
        return rejectWithValue({ data: err.response.data.ERROR });
      }
    }
  }
);





// //SCREENS
// // ** CREATE NEW SCREEN
// export const createScreen = createAsyncThunk(
//   "create-screen",
//   async ({ project }, { rejectWithValue }) => {
//     try {
//       const res = await axios.post("/api/screens/create", {
//         projectId: project,
//         coords: { coordY: "10", coordX: "200" },
//       });

//       return res.data;
//     } catch (err) {
//       if (axios.isAxiosError(err) && err.response) {
//         return rejectWithValue({ data: err.response.data.ERROR });
//       }
//     }
//   }
// );

// ** CREATE NEW SCREEN
export const seedDataBase = createAsyncThunk(
  "seed-database",
  async ({ projects }, { rejectWithValue }) => {
    try {
      const res = await axios.post(`/api/units/seed`, {
        projects,
      });
      return res.data;
    } catch (err) {
      if (axios.isAxiosError(err) && err.response) {
        return rejectWithValue({ data: err.response.data.ERROR });
      }
    }
  }
);

// ** CREATE NEW SCREEN
export const fetchScreensByProjectId = createAsyncThunk(
  "fetch-allScreens",
  async ({ project }, { rejectWithValue }) => {
    try {
      const res = await axios.get(`/api/screens/getscreenlist/${project}`);

      return res.data;
    } catch (err) {
      if (axios.isAxiosError(err) && err.response) {
        return rejectWithValue({ data: err.response.data.ERROR });
      }
    }
  }
);

//** SET SCREEN */
export const setScreen = createAsyncThunk(
  "setScreen",
  async ({ screenId, kioskId, name }, { rejectWithValue }) => {
    try {
      const res = await axios.put(`/api/screens/setscreen`, {
        screenId,
        kioskId,
        name,
      });

      return res.data;
    } catch (err) {
      if (axios.isAxiosError(err) && err.response) {
        return rejectWithValue({ data: err.response.data.ERROR });
      }
    }
  }
);

//SCREENS
// ** DELETE SCREEN
export const deleteScreen = createAsyncThunk(
  "delete-screen",
  async ({ screenId }, { rejectWithValue }) => {
    try {
      await axios.delete("/api/screens/delete", { data: { screenId } });

      return screenId;
    } catch (err) {
      if (axios.isAxiosError(err) && err.response) {
        return rejectWithValue({ data: err.response.data.ERROR });
      }
    }
  }
);

// ** DELETE LOGO
export const deleteLogo = createAsyncThunk(
  "delete-logo",
  async ({ unitId }, { rejectWithValue }) => {
    try {
      let res = await axios.delete("/api/units/pic/delete", {
        data: { unitId },
      });

      return res.data;
    } catch (err) {
      if (axios.isAxiosError(err) && err.response) {
        return rejectWithValue({ data: err.response.data.ERROR });
      }
    }
  }
);

//////////////////////////////////////////////////////////////////////////////
// UNITS
// ** FETCH DEAL MEMO LIST
export const createUnit = createAsyncThunk(
  "create-unit",
  async ({ projectId, floor, suite, companyName }, { rejectWithValue }) => {
    try {
      const res = await axios.post("/api/units/create", {
        projectId,
        floor,
        suite,
        companyName,
      });

      return res.data;
    } catch (err) {
      if (axios.isAxiosError(err) && err.response) {
        return rejectWithValue({ data: err.response.data.ERROR });
      }
    }
  }
);

// ** FETCH DEAL MEMO LIST
export const fetchUnitsByProjectId = createAsyncThunk(
  "units",
  async (projectId) => {
    const res = await axios.get(`/api/units/${projectId}`);
    return res.data.sort((a, b) =>
      a.suite > b.suite ? 1 : b.suite > a.suite ? -1 : 0
    );
  }
);

// ** DELETE UNIT
export const deleteUnits = createAsyncThunk(
  "deleteUnit",
  async ({ unitIds, projectId }, { dispatch }) => {
    const newIds = unitIds;
    await axios.delete(`/api/units/delete`, { data: unitIds });
    dispatch(fetchUnitsByProjectId(projectId));
    return newIds;
  }
);

// ** UPDATE UNIT
export const updateUnits = createAsyncThunk(
  "updateUnit",
  async ({
    unitId,
    companyName,
    logo,
    floor,
    categories,
    amenities,
    suiteData,
    path
  }) => {
    const res = await axios.put(`/api/units/update`, {
      unitId,
      companyName,
      logo,
      floor,
      categories,
      amenities,
      suiteData,
      path
    });
    return res.data;
  }
);

/*=====  End of ASYNC THINK FUNCTIONS  ======*/

/*============================================
=             REGISTER EXTRA REDUCERS              =
=============================================*/
function extraReducers(builder) {
  //CREATE UNIT
  builder.addCase(seedDataBase.pending, (state, action) => {
    return {
      ...state,
      loading: true,
      isInitialized: true,
    };
  });
  builder.addCase(seedDataBase.fulfilled, (state, action) => {
    toast.success("You have successfully uploaded your Guest Data!");

    return {
      ...state,
      units: [...action.payload],
    };
  });
  builder.addCase(seedDataBase.rejected, (state, action) => {
    toast.error("unable to upload guest data at this time");
    return {
      ...state,
      loading: false,
    };
  });

  //CREATE UNIT
  builder.addCase(createUnit.pending, (state, action) => {
    return {
      ...state,
      loading: true,
      isInitialized: true,
    };
  });
  builder.addCase(createUnit.fulfilled, (state, action) => {
    toast.success("You have successfully created a Unit!");
    let newUnits = [...state.units, action.payload];

    return {
      ...state,
      units: [...newUnits],
      currentSuite: [...state.currentSuite, action.payload],
    };
  });
  builder.addCase(createUnit.rejected, (state, action) => {
    if (action.payload.data && action.payload.data.keyPattern.suite) {
      toast.error("Only One company per Unit at this time.");
    } else {
      toast.error("Cannot create unit at this time !");
    }

    return {
      ...state,
      loading: false,
    };
  });

  //FETCH ALL UNITS
  builder.addCase(fetchUnitsByProjectId.pending, (state, action) => {
    return {
      ...state,
      loadingUnits: true,
    };
  });
  builder.addCase(fetchUnitsByProjectId.fulfilled, (state, action) => {
    return {
      ...state,
      loadingUnits: false,
      units: [...action.payload],
    };
  });
  builder.addCase(fetchUnitsByProjectId.rejected, (state, action) => {
    toast.error("Error fetching products");
    return {
      ...state,
      loading: false,
      unitsError: "Error fetching products",
    };
  });

  //   //DELETE PROJECT
  builder.addCase(deleteUnits.pending, (state, action) => {
    return {
      ...state,
      loadingDelete: true,
    };
  });
  builder.addCase(deleteUnits.fulfilled, (state, action) => {
    toast.success("Successfully deleted unit");
    return {
      ...state,
      loadingDelete: false,
      units: [...state.units.filter((p) => p._id !== action.payload)],
      currentSuite: [
        ...state.currentSuite.filter((p) => p._id !== action.payload),
      ],
    };
  });
  builder.addCase(deleteUnits.rejected, (state, action) => {
    toast.error("Could not delete project at this time!");
    return {
      ...state,
      loadingDelete: false,
    };
  });

  //   //DELETE PROJECT
  builder.addCase(deleteLogo.pending, (state, action) => {
    return {
      ...state,
    };
  });
  builder.addCase(deleteLogo.fulfilled, (state, action) => {
    toast.success("Successfully removed Logo");
    return {
      ...state,
      // loadingUnits: false,
      units: [...action.payload],
    };
  });
  builder.addCase(deleteLogo.rejected, (state, action) => {
    toast.error("Could not remove Logo at this time!");
    return {
      ...state,
    };
  });

  //   //UPDATE PROJECT
  builder.addCase(updateUnits.pending, (state, action) => {
    return {
      ...state,
      loadingUnits: true,
    };
  });
  builder.addCase(updateUnits.fulfilled, (state, action) => {
    toast.success("Successfully updated unit");

    return {
      ...state,
      units: [...action.payload],
      // currentSuite:[ ...state.currentSuite.map(s => (s._id.toString() === action.payload._id.toString()) ? action.payload : s),action.payload]
    };
  });
  builder.addCase(updateUnits.rejected, (state, action) => {
    toast.error("Could not update unit at this time!");
    return {
      ...state,
    };
  });

  // CREATE TENNANT
  builder.addCase(createNewTennant.pending, (state, action) => {
    return {
      ...state,
      loadingUnits: true,
    };
  });
  builder.addCase(createNewTennant.fulfilled, (state, action) => {
    toast.success("Successfully updated unit");

    return {
      ...state,
      tennants: [...state.tennants, action.payload],
      // currentSuite:[ ...state.currentSuite.map(s => (s._id.toString() === action.payload._id.toString()) ? action.payload : s),action.payload]
    };
  });
  builder.addCase(createNewTennant.rejected, (state, action) => {
    toast.error("Could not update unit at this time!");
    return {
      ...state,
    };
  });

  // FETCH ALL TENNANTS
  builder.addCase(getAllTennants.pending, (state, action) => {
    return {
      ...state,
      loadingUnits: true,
    };
  });
  builder.addCase(getAllTennants.fulfilled, (state, action) => {
    return {
      ...state,
      tennants: action.payload,
    };
  });
  builder.addCase(getAllTennants.rejected, (state, action) => {

    return {
      ...state,
    };
  });

  // DELETE TENANTS
  builder.addCase(deleteTenant.pending, (state, action) => {
    return {
      ...state,
      loadingUnits: true,
    };
  });
  builder.addCase(deleteTenant.fulfilled, (state, action) => {
    return {
      ...state,
      tennants: action.payload,
    };
  });
  builder.addCase(deleteTenant.rejected, (state, action) => {

    return {
      ...state,
    };
  });

  builder.addCase(seedTenantDataBase.pending, (state, action) => {
    return {
      ...state,
      loading: true,
      isInitialized: true,
    };
  });
  builder.addCase(seedTenantDataBase.fulfilled, (state, action) => {
    toast.success("You have successfully uploaded your Guest Data!");

    return {
      ...state,
      tennants: [...action.payload],
    };
  });
  builder.addCase(seedTenantDataBase.rejected, (state, action) => {
    toast.error("unable to upload guest data at this time");
    return {
      ...state,
      loading: false,
    };
  });
  
  builder.addCase(updateTennant.pending, (state, action) => {
    return {
      ...state,
      loading: true,
      isInitialized: true,
    };
  });
  builder.addCase(updateTennant.fulfilled, (state, action) => {
    toast.success("You have successfully uploaded your Guest Data!");
    console.log(action.payload, '<---------------');

    // Find the index of the tenant with the specified ID in the state
    const index = state.tennants.findIndex(tenant => tenant._id === action.payload._id);

    // If the tenant exists, update their information
    if (index !== -1) {
        state.tennants[index] = action.payload;
    } else {
        // If the tenant does not exist, add them to the array
        state.tennants.push(action.payload);
    }
});
  builder.addCase(updateTennant.rejected, (state, action) => {
    toast.error("unable to upload guest data at this time");
    return {
      ...state,
      loading: false,
    };
  });
  



}

export const unitSlice = createSlice({
  name: "units",
  initialState,
  reducers: {
    currentSuiteChange: (state, action) => {
      return {
        ...state,
        currentSuite: [...action.payload],
      };
    },
  },
  extraReducers,
});

export const { currentSuiteChange } = unitSlice.actions;
// Action creators are generated for each case reducer function
// export const { } = authSlice.actions;

export default unitSlice.reducer;
