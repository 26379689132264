// ** Redux Imports
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import toast from "react-hot-toast";

// ** Axios Imports
import axios from "axios";

/*=============================================
=                INITIAL STATES              =
=============================================*/

const initialState = {
  currentScreen: [],
  screens: [],
};

/*=============================================
=            ASYNC THUNK FUNCTIONS            =
=============================================*/

//SCREENS
// ** CREATE NEW SCREEN
export const createScreen = createAsyncThunk(
  "create-screen",
  async ({ accountId }, { rejectWithValue }) => {
    try {
      const res = await axios.post("/api/screens/create", {
        accountId,
      });

      return res.data;
    } catch (err) {
      if (axios.isAxiosError(err) && err.response) {
        return rejectWithValue({ data: err.response.data.ERROR });
      }
    }
  }
);

// ** CREATE NEW SCREEN
export const fetchScreensByAccountId = createAsyncThunk(
  "fetch-allScreens",
  async ({ accountId }, { rejectWithValue }) => {
    try {
      const res = await axios.get(`/api/screens/getscreenlist/${accountId}`);

      return res.data;
    } catch (err) {
      if (axios.isAxiosError(err) && err.response) {
        return rejectWithValue({ data: err.response.data.ERROR });
      }
    }
  }
);

//** SET SCREEN */
export const setScreen = createAsyncThunk(
  "setScreen",
  async ({ screenId, kioskId, name }, { rejectWithValue }) => {
    try {
      const res = await axios.put(`/api/screens/setscreen`, {
        screenId,
        kioskId,
        name,
      });
      return res.data;
    } catch (err) {
      if (axios.isAxiosError(err) && err.response) {
        return rejectWithValue({ data: err.response.data.ERROR });
      }
    }
  }
);

//** UPDATE SCREEN */
export const updateScreen = createAsyncThunk(
  "updateScreen",
  async ({ screenId, projects, name }, { rejectWithValue }) => {
    try {
      const res = await axios.put(`/api/screens/update`, {
        screenId,
        projects,
        name,
      });
      return res.data;
    } catch (err) {
      if (axios.isAxiosError(err) && err.response) {
        return rejectWithValue({ data: err.response.data.ERROR });
      }
    }
  }
);

//** UPDATE PLAYLIST SCREEN */
export const updatePlaylistScreen = createAsyncThunk(
  "updatePlaylistScreen",
  async ({ projectId }, { rejectWithValue }) => {
    try {
      const res = await axios.put(`/api/screens/updatelocationscreen`, {
        projectId,
      });
      return res.data;
    } catch (err) {
      if (axios.isAxiosError(err) && err.response) {
        return rejectWithValue({ data: err.response.data.ERROR });
      }
    }
  }
);

//SCREENS
// ** DELETE SCREEN
export const deleteScreen = createAsyncThunk(
  "delete-screen",
  async ({ screens }, { rejectWithValue }) => {
    try {
      await axios.delete("/api/screens/delete", { data: { screens } });

      return screens;
    } catch (err) {
      if (axios.isAxiosError(err) && err.response) {
        return rejectWithValue({ data: err.response.data.ERROR });
      }
    }
  }
);



// ** SET THEME TO SCREEN 
export const setTheme = createAsyncThunk(
  "set-Theme",
  async ({ themeId,screenId }, { rejectWithValue }) => {
    try {
      await axios.put("/api/screens/setTheme", {themeId, screenId});

      return 'success'
    } catch (err) {
      if (axios.isAxiosError(err) && err.response) {
        return rejectWithValue({ data: err.response.data.ERROR });
      }
    }
  }
);



// ** SET THEME TO SCREEN 
export const getScreen = createAsyncThunk(
  "get-Theme",
  async ({screenId}, { rejectWithValue }) => {

    try {
      const res = await axios.get(`/api/screens/getscreen/${screenId}`);

      return res.data
    } catch (err) {
      if (axios.isAxiosError(err) && err.response) {
        return rejectWithValue({ data: err.response.data.ERROR });
      }
    }
  }
);





/*=====  End of ASYNC THINK FUNCTIONS  ======*/

/*============================================
=             REGISTER EXTRA REDUCERS              =
=============================================*/
function extraReducers(builder) {
  //CREATE SCREEN
  builder.addCase(createScreen.pending, (state, action) => {
    return {
      ...state,
      loading: true,
      isInitialized: true,
    };
  });
  builder.addCase(createScreen.fulfilled, (state, action) => {
    toast.success("You have successfully created a Screen!");

    return {
      ...state,
      screens: [...state.screens, action.payload],
    };
  });
  builder.addCase(createScreen.rejected, (state, action) => {
    toast.error("Cannot create a screen at this time !");

    return {
      ...state,
      loading: false,
    };
  });

  //FETCH SCREENS BY ACCOUNT ID
  builder.addCase(fetchScreensByAccountId.pending, (state, action) => {
    return {
      ...state,
      loading: true,
      isInitialized: true,
    };
  });
  builder.addCase(fetchScreensByAccountId.fulfilled, (state, action) => {
    return {
      ...state,
      screens: [...action.payload],
    };
  });
  builder.addCase(fetchScreensByAccountId.rejected, (state, action) => {
    toast.error("Cannot create a screen at this time !");

    return {
      ...state,
      loading: false,
    };
  });

  //FETCH SCREENS BY PROJECT ID
  builder.addCase(setScreen.pending, (state, action) => {
    return {
      ...state,
      loading: true,
      isInitialized: true,
    };
  });
  builder.addCase(setScreen.fulfilled, (state, action) => {
    return {
      ...state,
      screens: [...action.payload],
    };
  });
  builder.addCase(setScreen.rejected, (state, action) => {
    toast.error("Cannot set screen at this time");

    return {
      ...state,
      loading: false,
    };
  });

  //UPDATE SCREEN
  builder.addCase(updateScreen.pending, (state, action) => {
    return {
      ...state,
      loading: true,
      isInitialized: true,
    };
  });
  builder.addCase(updateScreen.fulfilled, (state, action) => {
    toast.success("You have successfully updated the Screen!");
    return {
      ...state,
      screens: [...action.payload],
    };
  });

  builder.addCase(updateScreen.rejected, (state, action) => {
    toast.error("Cannot update screen at this time!");

    return {
      ...state,
      loading: false,
    };
  });

  //DELETE SCREEN
  builder.addCase(deleteScreen.pending, (state, action) => {
    return {
      ...state,
      loading: true,
      isInitialized: true,
    };
  });
  builder.addCase(deleteScreen.fulfilled, (state, action) => {
    toast.success("You have successfully deleted the Screen!");
    const idsToRemove = action.payload;

    state.screens = state.screens.filter(
      (screen) => !idsToRemove.includes(screen._id)
    );
  });
  builder.addCase(deleteScreen.rejected, (state, action) => {
    toast.error("Cannot delete screen at this time!");

    return {
      ...state,
      loading: false,
    };
  });

  //UPDATE SCREEN
  builder.addCase(updatePlaylistScreen.pending, (state, action) => {
    return {
      ...state,
      loading: true,
      isInitialized: true,
    };
  });
  builder.addCase(updatePlaylistScreen.fulfilled, (state, action) => {
    toast.success("You have successfully updated the Screens");
    return {
      ...state,
    };
  });
  builder.addCase(updatePlaylistScreen.rejected, (state, action) => {
    toast.error("Cannot update screen at this time!");
    return {
      ...state,
      loading: false,
    };
  });

    //SET THEME
    builder.addCase(setTheme.pending, (state, action) => {
      return {
        ...state,
        loading: true,
        isInitialized: true,
      };
    });
    builder.addCase(setTheme.fulfilled, (state, action) => {
      toast.success("You have successfully selected a theme");
      return {
        ...state,
      };
    });
    builder.addCase(setTheme.rejected, (state, action) => {
      toast.error("Cannot select a theme at this time");
      return {
        ...state,
        loading: false,
      };
    });

        //SET THEME
        builder.addCase(getScreen.pending, (state, action) => {
          return {
            ...state,
            loading: true,
            isInitialized: true,
          };
        });
        builder.addCase(getScreen.fulfilled, (state, action) => {

          return {
            ...state,
            screen:action.payload
          };
        });
        builder.addCase(getScreen.rejected, (state, action) => {
          return {
            ...state,
            loading: false,
          };
        });
}

export const screenSlice = createSlice({
  name: "screens",
  initialState,
  reducers: {
    // currentSuiteChange: (state, action) => {
    //   return {
    //     ...state,
    //     currentSuite: [...action.payload],
    //   };
    // },
  },
  extraReducers,
});

// Action creators are generated for each case reducer function
// export const { } = authSlice.actions;

export default screenSlice.reducer;
