// ** Redux Imports
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import toast from "react-hot-toast";

// ** Axios Imports
import axios from "axios";

/*=============================================
=                INITIAL STATES              =
=============================================*/

const initialState = {
  attributes: [],
  userAttributes: [],
};

/*=============================================
=            ASYNC THUNK FUNCTIONS            =
=============================================*/

///////////////////ADMIN START////////////////
// ** CREATE PROJECT
export const createAttribute = createAsyncThunk(
  "create-Attribute",
  async ({ projectId }) => {
    const res = await axios.post("/api/builders/filters/create/", {
      projectId,
    });
    return res.data;
  }
);

// // ** DELETE FILTER
export const deleteFilter = createAsyncThunk(
  "deleteFilter",
  async ({ projectId, filtersId }) => {
    const res = await axios.delete("/api/builders/filters/delete", {
      data: { projectId, filtersId },
    });
    return res.data;
  }
);

// ** GET ALL FILTERS
export const getAllFilters = createAsyncThunk(
  "getallFilters",
  async ({ projectId }) => {
    const res = await axios.get(`/api/builders/filters/${projectId}`);
    return res.data;
  }
);

// ** UPDATE FILTER
export const updateFilter = createAsyncThunk(
  "update-Attribute",
  async ({
    projectId,
    filterId,
    name,
    filterComponentType,
    filterValueType,
  }) => {
    const res = await axios.patch("/api/builders/filters/update/", {
      projectId,
      filterId,
      name,
      filterComponentType,
      filterValueType,
    });
    return res.data;
  }
);
/////////////////////////////////////////////////////////ADMIN END///////////////////////////////////////////////////////

/////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

/////////////////////////////////////////////////////////USER START//////////////////////////////////////////////////////
// ** CREATE PROJECT
export const createUserAttribute = createAsyncThunk(
  "createUserAttribute",
  async ({ projectId, projectType }) => {
    if(projectType ==='Seaty' || projectType ==='TouchDirectory'){
      const res = await axios.post(`/api/attributes/project/create`,{
        projectId
      });
      return res.data;
    }else {
 const res = await axios.post("/api/builders/filters/attribute/create/", {
      projectId,
    });
    return res.data;
    }
   
  }
);

// // ** DELETE FILTER
export const deleteUserAttribute = createAsyncThunk(
  "deleteUserAttribute",
  async ({ projectId, attributesId, projectType }) => {

    if(projectType === 'Seaty' || projectType ==='TouchDirectory'){
      const res = await axios.delete(`/api/attributes/project/delete`,{
        data: { projectId, attributesId },
      });
      return res.data;
    }else {

      const res = await axios.delete("/api/builders/filters/attribute/delete", {
        data: { projectId, attributesId },
      });
      return res.data;
    }
  }
);

// ** GET ALL FILTERS
export const getAllUserAttribute = createAsyncThunk(
  "getallUserAttributes",
  async ({ projectId, projectType }) => {
    console.log(projectId,projectType)
    if(projectType === 'Seaty' || projectType ==='TouchDirectory'){
      const res = await axios.get(`/api/attributes/project/${projectId}`);
      return res.data;
    }else {
      const res = await axios.get(`/api/builders/filters/attribute/${projectId}`);
      return res.data;
    }

  }
);

// ** UPDATE FILTER
export const updateUserAttribute = createAsyncThunk(
  "updateUserAttributes",
  async ({
    projectId,
    attributeId,
    titleName,
    attribute,
    attributeType,
    enableCard,
    enable,
    filterable,
    filterType,
    projectType
  }) => {


    if(projectType ==='Seaty' || projectType ==='TouchDirectory'){
      const res = await axios.patch(`/api/attributes/project/update`,{
        projectId,
        attributeId,
        titleName,
        attribute,
        attributeType,
        enableCard,
        enable,
        filterable,
        filterType,
      });
      return res.data;
    }else {
      const res = await axios.patch("/api/builders/filters/attribute/update/", {
        projectId,
        attributeId,
        titleName,
        attribute,
        attributeType,
        enableCard,
        enable,
        filterable,
        filterType,
      });
      return res.data;
    }
    
  }
);
///////////////////USER END////////////////

/*=====  End of ASYNC THINK FUNCTIONS  ======*/

/*============================================
=             REGISTER EXTRA REDUCERS              =
=============================================*/
function extraReducers(builder) {
  //CREATE FILTERS
  builder.addCase(createAttribute.pending, (state, action) => {
    return {
      ...state,
      loading: true,
      isInitialized: true,
    };
  });
  builder.addCase(createAttribute.fulfilled, (state, action) => {
    toast.success("You have successfully created an attribute!");

    return {
      ...state,
      attributes: [...state.attributes, action.payload],
    };
  });
  builder.addCase(createAttribute.rejected, (state, action) => {
    toast.error("Invalid Project Entry (make sure your ids are unique)");
    return {
      ...state,
      loading: false,
    };
  });

  //GET ALL FILTERS
  builder.addCase(getAllFilters.pending, (state, action) => {
    return {
      ...state,
      loading: true,
      isInitialized: true,
    };
  });
  builder.addCase(getAllFilters.fulfilled, (state, action) => {
    return {
      ...state,
      attributes: [...action.payload],
    };
  });
  builder.addCase(getAllFilters.rejected, (state, action) => {
    toast.error("An error has occured! trying to fetch attributes list");
    return {
      ...state,
      loading: false,
    };
  });

  //UPDATE FILTER
  builder.addCase(updateFilter.pending, (state, action) => {
    return {
      ...state,
      loading: true,
      isInitialized: true,
    };
  });
  builder.addCase(updateFilter.fulfilled, (state, action) => {
    toast.success("Successfully updated filter");
    return {
      ...state,
      attributes: [...action.payload],
    };
  });
  builder.addCase(updateFilter.rejected, (state, action) => {
    toast.error("An error has occured! trying to remove filter at this time.");
    return {
      ...state,
      loading: false,
    };
  });

  //DELETE FILTER BY ID
  builder.addCase(deleteFilter.pending, (state, action) => {
    return {
      ...state,
      loading: true,
      isInitialized: true,
    };
  });
  builder.addCase(deleteFilter.fulfilled, (state, action) => {
    toast.success("Successfully removed filter");
    return {
      ...state,
      attributes: [...action.payload],
    };
  });
  builder.addCase(deleteFilter.rejected, (state, action) => {
    toast.error("An error has occured! trying to remove filter at this time.");
    return {
      ...state,
      loading: false,
    };
  });

  /////////////////////////////////////////////USER START/////////////////////////////////////////////
  //CREATE USER ATTRIBUTES
  builder.addCase(createUserAttribute.pending, (state, action) => {
    return {
      ...state,
      loading: true,
      isInitialized: true,
    };
  });
  builder.addCase(createUserAttribute.fulfilled, (state, action) => {
    toast.success("You have successfully added an attribute to your project!");

    return {
      ...state,
      userAttributes: [...state.userAttributes, action.payload],
    };
  });
  builder.addCase(createUserAttribute.rejected, (state, action) => {
    toast.error("Invalid Project Entry (make sure your ids are unique)");
    return {
      ...state,
      loading: false,
    };
  });

  //GET ALL USER ATTRIBUTES
  builder.addCase(getAllUserAttribute.pending, (state, action) => {
    return {
      ...state,
      loading: true,
      isInitialized: true,
    };
  });
  builder.addCase(getAllUserAttribute.fulfilled, (state, action) => {
    return {
      ...state,
      userAttributes: [...action.payload],
    };
  });
  builder.addCase(getAllUserAttribute.rejected, (state, action) => {
    toast.error("An error has occured! trying to fetch attributes list");
    return {
      ...state,
      loading: false,
    };
  });

  //UPDATE USER ATTRIBUTES
  builder.addCase(updateUserAttribute.pending, (state, action) => {
    return {
      ...state,
      loading: true,
      isInitialized: true,
    };
  });
  builder.addCase(updateUserAttribute.fulfilled, (state, action) => {
    toast.success("Successfully updated attribute");
    return {
      ...state,
      userAttributes: [...action.payload],
    };
  });
  builder.addCase(updateUserAttribute.rejected, (state, action) => {
    toast.error("An error has occured! trying to update attribute at this time.");
    return {
      ...state,
      loading: false,
    };
  });

  //DELETE FILTER BY ID
  builder.addCase(deleteUserAttribute.pending, (state, action) => {
    return {
      ...state,
      loading: true,
      isInitialized: true,
    };
  });
  builder.addCase(deleteUserAttribute.fulfilled, (state, action) => {
    toast.success("Successfully removed attribute from project");
    return {
      ...state,
      userAttributes: [...action.payload],
    };
  });
  builder.addCase(deleteUserAttribute.rejected, (state, action) => {
    toast.error("An error has occured! trying to remove attribute at this time.");
    return {
      ...state,
      loading: false,
    };
  });
}


export const filtersReducer = createSlice({
  name: "project",
  initialState,
  reducers: {
    // selectUsersId: (state, action) => {
    //   const newVal = [
    //     ...state.projects.filter(
    //       (p) => p.userId.toString() === action.payload.toString()
    //     ),
    //   ];
    //   localStorage.setItem("selectedPro", JSON.stringify(newVal));
    //   return {
    //     ...state,
    //     userProjectId: action.payload,
    //     userProjects: [
    //       ...state.projects.filter(
    //         (p) => p.userId.toString() === action.payload.toString()
    //       ),
    //     ],
    //   };
    // },
    // chooseProjectForExtra: (state, action) => {
    //   localStorage.setItem("chosenProject", JSON.stringify(action.payload));
    //   return {
    //     ...state,
    //     chosenProject: action.payload,
    //   };
    // },
    // removeProjectForExtra: (state, action) => {
    //   localStorage.removeItem("chosenProject");
    //   return {
    //     ...state,
    //     chosenProject: [],
    //   };
    // },
  },
  extraReducers,
});

export const {} = filtersReducer.actions;
// Action creators are generated for each case reducer function
// export const { } = authSlice.actions;

export default filtersReducer.reducer;
